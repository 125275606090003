import React from "react"

import "./footer.css"

const Footer = () => (
  <footer>
    <div className="footerContent">
      <div>© {new Date().getFullYear()} Artxcode Ltd &#183; Need work done? <a className="contact" href="mailto:yosuf@artxcode.co">Contact us</a></div>
      <a className="social" href="https://twitter.com/artxcode" target="__blank"><i className="fab fa-twitter"></i></a>
    </div>
  </footer>
)

export default Footer
